.menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
}

.menuBackground {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: var(--primary);
  z-index: -1;
  transform: translateY(0);
}

.imgBackground {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.1;
}

.menuItems {
  position: absolute;
  width: max-content;
  bottom: 0;
  right: 20px;
  pointer-events: all;
  padding-bottom: 30%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  perspective: 700px;
}

.linkContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 30px;
  transform-style: preserve-3d;
  transform: rotateY(-25deg);
}

.listItem {
  transform-origin: center right;
  transition: all 300ms ease-out;
  transform: rotateX(0deg);
}

.menuLinks {
  font-family: var(--primary-font);
  color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  text-transform: uppercase;
  font-size: 50px;
  font-weight: 900;
  line-height: 100%;
  transition: all 300ms ease-out;
}

.listItem:hover .menuLinks {
  color: transparent;
}

@media (min-width: 768px) {
  .menuLinks {
    font-size: 80px;
  }

  .menuItems {
    right: 10%;
    padding-bottom: 10%;
  }
}

@media (min-width: 992px) {
  .menuLinks {
    font-size: 100px;
  }

  .menuItems {
    padding-bottom: 50px;
  }
}

@media (min-width: 1920px) {
  .menuItems {
    right: 20%;
    padding-bottom: 100px;
  }
}
