.footerContainer {
  width: 100%;
  padding: 200px 4vw 0 4vw;

  display: flex;
  flex-direction: column;
}

.contentContainer {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.title {
  font-size: clamp(50px, 4.5vw, 100px);
  font-weight: 800;
  text-transform: uppercase;
  line-height: 90%;
  margin-bottom: 30px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--primary);
  color: transparent;
}

.contactInfo p {
  font-size: 20px;
  font-weight: 500;
  color: var(--off-white);
  line-height: 100%;
  margin-bottom: 1vh;
  font-size: var(--secondary-font);
}

.contactInfo a {
  font-size: clamp(20px, 5.5vw, 100px);
  font-weight: 700;
  text-transform: uppercase;
  color: white;
  line-height: 90%;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  transition: all 1000ms ease;
}

.contactInfo a:hover {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--primary);
  color: transparent;
  transition: all 100ms ease;
}

.contactInfo {
  margin-bottom: clamp(20px, 3vw, 80px);
}

.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  border-top: 1px solid var(--off-white);
  padding: 40px 0;
}

.footer p {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: var(--primary-font);
  letter-spacing: 1px;
}

.footer a {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: var(--secondary-font);
  letter-spacing: 1px;
  color: var(--primary);
  text-decoration: underline;
  transition: all 200ms;
}

.footer a:hover {
  color: var(--blue);
}

@media (width > 768px) {
  .footerContainer {
    width: 100%;
    height: 100vh;
    padding: 0 4vw;
  }

  .contactInfo p {
    margin-left: 5px;
  }
}
