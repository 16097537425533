.row {
  position: relative;
  width: 100%;
  padding: 50px 0;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.line {
  width: 100%;
  height: 2px;
  background: white;
}

.bottomLine {
  position: absolute;
  bottom: 0;
}

.cell {
  --first-column-width: 50%;
  --second-column-width: calc(100% - var(--first-column-width));
  width: var(--second-column-width);
  font-size: clamp(16px, 3vw, 22px);
  text-transform: uppercase;
  font-weight: 500;
  line-height: 100%;
  overflow: hidden;
}

.cell:not(:first-child, :nth-child(2)) {
  width: 100%;
  padding-left: var(--first-column-width);
  margin-top: 20px;
}

.cell:first-child {
  width: var(--first-column-width);
}

@media (min-width: 900px) {
  .row {
    flex-wrap: nowrap;
  }

  .cell {
    width: 24vw;
  }

  .cell:not(:first-child, :nth-child(2)) {
    width: 24vw;
    padding-left: 0;
    margin-top: 0;
  }

  .cell:first-child {
    width: 24vw;
  }
}
