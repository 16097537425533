.projectsContainer {
  position: relative;
  padding: 0 4vw;
  overflow: hidden;
  padding: 30px 0;
  overflow: hidden;
}

.title {
  position: absolute;
  writing-mode: vertical-rl;
  width: 100%;
  height: 100%;
  top: -50px;
  left: 0;
  font-size: 400px;
  opacity: 0.1;
  text-transform: uppercase;
  font-weight: 700;
  font-family: var(--primary-font);
}

.projectContainer {
  position: relative;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.projectContainer:not(:last-child) {
  margin-bottom: 150px;
}

.projectCard {
  position: relative;
  width: 300px;
  height: 450px;
  transform-origin: top center;
}

.projectCard::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  top: 0;
  left: 0;
  z-index: -1;
  filter: blur(20px);
  opacity: 0.3;
}

.projectNumber {
  position: absolute;
  font-size: 150px;
  font-weight: 800;
  font-family: var(--secondary-font);
  line-height: 100%;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: var(--primary);
  color: var(--primary);
}

.fillIndex {
  position: relative;
  z-index: -1;
}

.strokeIndex {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: var(--primary);
  color: transparent;
  z-index: 3;
}

.posLeft {
  left: 20px;
}

.posRight {
  right: 20px;
}

.rotateLeft {
  transform: translateX(20%) rotate(-5deg);
}

.rotateRight {
  transform: translateX(-20%) rotate(5deg);
}

.projectCard img {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

@media (max-height: 800px) {
  .projectsContainer {
    padding: 80px 0;
  }

  .projectContainer:not(:last-child) {
    margin-bottom: 200px;
  }

  .projectContainer:first-child {
    margin-top: 300px;
  }
}
