.aboutContainer {
  padding: 100px 4vw 40px 4vw;
}

.text {
  color: var(--white);
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.3;
}

.text p {
  overflow: hidden;
}

.text:not(:last-child) {
  margin-bottom: 35px;
}

.cvButton {
  border: none;
  background-color: #ffffff10;
  color: #fff;
  padding: 15px 25px;
  margin-top: 15px;
  font-size: 15px;
  font-weight: 600;
  line-height: 100%;
  transition: all 200ms ease;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.cvButtonIcon {
  color: var(--primary);
  transition: all 200ms ease;
}

.cvButton:hover {
  background-color: #ffffff;
  color: var(--dark);
}

.cvButton:hover .cvButtonIcon {
  color: var(--dark);
}

mark {
  color: var(--primary);
}

@media (min-width: 768px) {
  .text {
    font-size: 35px;
  }

  .textContainer {
    max-width: 1360px;
  }

  .cvButton {
    font-size: 20px;
  }
}
