.nav {
  position: absolute;
  width: fit-content;
  top: 0px;
  right: 0px;
  z-index: 101;
  isolation: isolate;
  pointer-events: none;
}

.navButton {
  position: fixed;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  pointer-events: all;
  cursor: pointer;
}

.icon {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .navButton {
    width: 45px;
    height: 45px;
    margin: 30px;
  }
}
