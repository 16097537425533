@import 'bootstrap/dist/css/bootstrap.min.css';

@font-face {
  font-family: 'Metropolis';
  src: url('../public/fonts/metropolis/Metropolis-Thin.otf') format('otf'),
    url('../public/fonts/metropolis/Metropolis-ExtraLight.otf') format('otf'),
    url('../public/fonts/metropolis/Metropolis-Light.otf') format('otf'),
    url('../public/fonts/metropolis/Metropolis-Regular.otf') format('otf'),
    url('../public/fonts/metropolis/Metropolis-Medium.otf') format('otf'),
    url('../public/fonts/metropolis/Metropolis-SemiBold.otf') format('otf'),
    url('../public/fonts/metropolis/Metropolis-Bold.otf') format('otf'),
    url('../public/fonts/metropolis/Metropolis-ExtraBold.otf') format('otf'),
    url('../public/fonts/metropolis/Metropolis-Black.otf') format('otf');
}

:root {
  --variable-font: 'Roboto Flex', sans-serif;
  --primary-font: 'Metropolis', sans-serif;
  --secondary-font: 'Poppins', sans-serif;
  --montserrat: 'Montserrat', sans-serif;
  --mono: 'Courier Prime', monospace;
  --roboto: 'Roboto', sans-serif;
  --merry: 'Merriweather', serif;
  --heebo: 'Heebo', serif;

  --white: #ffffff;
  --off-white: #d6d6d6;
  --dark-white: #fefefe;
  --dark-white: #e3e1e3;
  --light-dark: #26252c;
  --dark: #151f29;
  --black: #000000;

  --primary: #ffab0f;
  --primary2: #715bff;
  --secondary: #ff665b;
  --blue: #4c84f0;
  --quaternary: #fff;
  --quinary: #fff;

  --grey1: #f2f2f2;
  --grey2: #919191;
  --grey3: rgb(47, 47, 47);
  --grey4: rgb(28, 28, 28);

  --main-gradient: linear-gradient(
    124deg,
    rgb(19, 19, 19) 26%,
    rgba(32, 32, 32, 1) 100%
  );
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

mark {
  background: none !important;
  padding: 0;
}

p {
  padding: 0;
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

body {
  width: 100%;
  overflow-x: hidden;
  font-family: var(--primary-font);
  color: var(--white);
  background-color: var(--dark);
}

::-moz-selection {
  color: var(--primary);
  background: var(--primary2);
}

::selection {
  color: var(--primary);
  background: var(--primary2);
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #00000040;
}

::-webkit-scrollbar-thumb {
  background: var(--primary);
}

::-webkit-scrollbar-thumb:hover {
  background: #ffab0f80;
}
