@keyframes pulse {
  from,
  to {
    scale: 1;
  }

  50% {
    scale: 1.08;
  }
}

.projectsContainer {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.title {
  position: absolute;
  font-size: 600px;
  top: 50%;
  transform: translateY(-50%);
  left: 10%;
  opacity: 0.8;
  text-transform: uppercase;
  font-weight: 700;
  font-family: var(--primary-font);

  display: flex;
  justify-content: center;
  align-items: center;
}

.projectsTrack {
  width: fit-content;
  height: 100%;
  display: grid;
  grid-auto-columns: 35vw;
  padding: 0 10vw;
}

.projectContainer {
  grid-row: 1;
  display: flex;
  justify-content: center;
  padding: 4vw;
}

.projectTitle {
  position: absolute;
  max-width: 60%;
  left: -10%;
  font-size: 24px;
  font-weight: 700;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(43, 43, 43, 0.4);
  padding: 20px 40px;
  color: var(--primary);
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  z-index: 2;
}

.projectContainer:nth-child(odd) {
  align-items: flex-end;
}

.projectContainer:nth-child(odd) .projectImageContainer {
  transform: rotate(-8deg);
}

.projectContainer:nth-child(even) {
  align-items: flex-start;
}

.projectContainer:nth-child(even) .projectImageContainer {
  transform: rotate(8deg);
}

.projectImageContainer {
  position: relative;
  width: 400px;
  height: 550px;
  transition: all 900ms ease;
}

.projectImageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.projectImageContainer::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  top: 0;
  left: 0;
  z-index: -1;
  filter: blur(20px);
  opacity: 0.5;
  transition: all 900ms ease;
}

.projectContainer .projectImageContainer:hover {
  transform: rotate(0deg) scale(1.1);
  transition: all 300ms ease;
  z-index: 3;
}
