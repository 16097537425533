.tabsNav {
  width: 100%;
}

.tabsBG {
  display: flex;
  background: #ffffff;
  width: max-content;
  border-radius: 100vw;
  padding: 3px;
  overflow: hidden;
}

.tab {
  font-size: 18px;
  font-weight: 600;
  padding: 10px 30px;
  cursor: pointer;
  color: var(--dark);
  line-height: 100%;
}

.tabActive {
  width: max-content;
  background: var(--primary);
  border-radius: 100vw;
}

.tab:hover:not(.tabActive) {
  border-radius: 100vw;
  background-color: #715bff30;
}

@media (max-width: 501px) {
  .tabsNav {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .tab {
    font-size: 13px;
    line-height: 'initial';
    white-space: nowrap;
  }
}
