.heroContainer {
  position: relative;
  width: 100%;
  height: 100vh;
  visibility: 0;
  opacity: 0;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 150px 20px 20px 20px;
}

.illustrationContainer {
  position: absolute;
  width: 450px;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.cubs {
  display: none;
}

.name {
  position: absolute;
  width: 100%;
  height: auto;
  max-width: 800px;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.contentContainer {
  max-width: 500px;
}

.contentContainer h1 {
  font-size: 40px;
  font-weight: 700;
  font-family: var(--primary-font);
  text-transform: uppercase;
  color: var(--white);
  overflow: hidden;
  display: hidden;
}

.buttonsContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  margin-top: 30px;
}

.secondaryText {
  width: 100%;
  margin-top: 25px;
  overflow: hidden;
}

.secondaryText p {
  max-width: 350px;
  font-family: var(--secondary-font);
  overflow: hidden;
  opacity: 0;
}

.textContainer {
  position: relative;
}

.bottomCurtain {
  position: absolute;
  width: 100%;
  height: 42vh;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--primary);
}

@media (min-width: 768px) {
  .heroContainer {
    align-items: flex-start;
    justify-content: center;
    padding: 0 clamp(70px, 7vw, 150px);
  }

  .illustrationContainer {
    position: absolute;
    width: 600px;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .contentContainer {
    max-width: initial;
  }

  .contentContainer h1 {
    font-size: clamp(50px, 7vw, 120px);
    line-height: clamp(50px, 7vw, 120px);
    font-weight: 600;
    overflow: hidden;
  }

  .buttonsContainer {
    flex-direction: row;
  }

  .secondaryText {
    width: initial;
    margin-top: 0px;

    position: absolute;
    bottom: 15%;
    right: 15%;
    overflow: hidden;
  }

  .secondaryText p {
    max-width: 400px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
  }

  .bottomCurtain {
    height: 35vh;
  }
}

@media (min-width: 2100px) {
  .contentContainer {
    max-width: 80%;
  }
}
