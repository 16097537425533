.projectPageContainer {
  max-width: 1920px;
  margin: 0 auto;
}

.hero {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 50px;
  padding: 80px 4vw;
  background-color: rgba(0, 0, 0, 0.1);
}

.title h1 {
  font-size: clamp(50px, 5.7vw, 100px);
  font-weight: 700;
  line-height: 100%;
  text-align: center;
}

.image {
  width: 100%;
  height: 600px;
  border-radius: 20px;
  overflow: hidden;
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.description {
  padding: 80px 4vw 20px 4vw;
  font-size: 18px;
  font-weight: 500;
}

.description p:not(:last-child) {
  margin-bottom: 20px;
}

.gallery {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
}

.galleryImgContainer {
  aspect-ratio: 19/11;
  width: 100%;
}

.galleryImgContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.prevNextNav {
  display: flex;
  justify-content: center;
  padding-right: 20px;
}

.prevNextBtn {
  font-size: clamp(20px, 1.7vw, 30px);
  font-weight: 700;
  text-transform: uppercase;
  color: white;
  background-color: #ffffff10;
  padding: 20px;
  transition: all 200ms ease;
  line-height: 100%;
}

.prevNextBtn:hover {
  color: var(--dark);
  background-color: #fff;
}

.prevBtn {
  left: 4vw;
  margin-right: 20px;
}

.buttonsContainer {
  padding: 10px 4vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  margin-bottom: 30px;
}

.btn {
  position: relative;
  text-transform: uppercase;
  text-decoration: none;
  padding: 15px 40px;
  border-radius: 100px;
  transition: all 0.2s;
}

.btn:hover {
  transform: translateY(-3px);
  color: var(--blue);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btn:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.btnWhite {
  background-color: #fff;
  color: #777;
}

.btn::after {
  content: '';
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}

.btnWhite::after {
  background-color: #fff;
}

.btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.btnAnimated {
  animation: moveInBottom 5s ease-out;
  animation-fill-mode: backwards;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@media (min-width: 786px) {
  .title h1 {
    max-width: 1000px;
    text-align: left;
  }

  .image {
    width: 450px;
  }
  .description {
    font-size: 22px;
  }
  .buttonsContainer {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
  }

  .gallery {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 1700px;
    margin: 0 auto;
  }
}
